import {createAlgoliaSearch} from './algolia-search-initializer';

document.addEventListener('turbolinks:load', () => {

  // Retrieve these from the global scope or have them passed into this script
  const algoliaAppId = window.algolia_app_id;
  const algoliaPublicKey = window.algolia_public_key;
  const algoliaIndexName = window.aloglia_collection_index_name;

  // Define the hit template for collections
  const collectionHitTemplate = `
    <a href="#" class="algolia-result py-2.5 px-3.5 hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-200 no-underline text-black block" data-slug="{{ slug }}" data-image-url="{{image_url}}" data-name="{{name}}">
        <div class="item-container min-h-full flex items-center">
            <div class="relative">
                <img src="{{image_url}}" alt="{{name}}" width="35" height="35" class="rounded z-0 relative float-left mr-3">
            </div>
            <div class="text text-xs leading-5 box-border max-w-full flex flex-col justify-center items-start">
                {{#name}}
                    <h6 class="m-0 font-medium">{{name}}</h6>
                {{/name}}
                {{#category_names.length}}
                    <div class="subtext leading-4 text-gray-700 text-left">
                        {{#category_names}}
                            <span class="subtext-item">{{.}}</span>
                        {{/category_names}}
                    </div>
                {{/category_names.length}}

                {{#_highlightResult.urls}}
                  <div class="highlight-url text-gray-500">
                    {{{ value }}}
                  </div>
                {{/_highlightResult.urls}}
            </div>
        </div>
    </a>
  `;

  // Initialize the main search for a profile
  if ($("#home-page-collection-search-box").length > 0) {
    createAlgoliaSearch(
      algoliaAppId,
      algoliaPublicKey,
      'home-page-collection',
      algoliaIndexName,
      collectionHitTemplate,
      (data) => {
        window.location.href = `/${data.slug}`; // Navigate to the URL derived from the slug
      },
      'Search for real people & brands',
      '<q>{{ query }}</q> is not on NotCommon yet...',
      'home-page-search-box',
      true  // Allow submitting on enter
    );
  }

  // Home page versus - Initialize the search for the first collection
  if ($("#home-first-versus-collection-search-box").length > 0) {
    createAlgoliaSearch(
      algoliaAppId,
      algoliaPublicKey,
      'home-first-versus-collection',
      algoliaIndexName,
      collectionHitTemplate,
      (data) => {
        // Set the hidden input value
        $('#first_user_hidden_input').val(data.slug);

        // Update the .first-user-selection div
        $('.first-user-selection .image').html(`<img src="${data.imageUrl}" alt="${data.name}" width="55" height="55" class="rounded-full mr-2" />`);
        $('.first-user-selection .text').text(data.name);
      },
      'Search for a person, brand, etc.',
      '<q>{{ query }}</q> is not on NotCommon yet...',
      'rounded-full py-3 px-8 w-full text-black placeholder:text-neutral-500 border-none ring-1 ring-inset ring-neutral-100 focus:ring-1 focus:ring-inset focus:ring-primary-300',
      false  // Disable submitting on enter
    );
  }

  // Home page versus - Initialize the search for the second collection
  if ($("#home-second-versus-collection-search-box").length > 0) {
    createAlgoliaSearch(
      algoliaAppId,
      algoliaPublicKey,
      'home-second-versus-collection',
      algoliaIndexName,
      collectionHitTemplate,
      (data) => {
        // Set the hidden input value
        $('#second_user_hidden_input').val(data.slug);

        // Update the .first-user-selection div
        $('.second-user-selection .image').html(`<img src="${data.imageUrl}" alt="${data.name}" width="55" height="55" class="rounded-full mr-2" />`);
        $('.second-user-selection .text').text(data.name);
      },
      'Search for a person, brand, etc.',
      '<q>{{ query }}</q> is not on NotCommon yet...',
      'rounded-full py-3 px-8 w-full text-black placeholder:text-neutral-500 border-none ring-1 ring-inset ring-neutral-100 focus:ring-1 focus:ring-inset focus:ring-primary-300',
      false  // Disable submitting on enter
    );
  }

});