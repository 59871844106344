import { createAlgoliaSearch } from './algolia-search-initializer';

document.addEventListener('turbolinks:load', () => {
  // console.log('DOM is ready');

  const algoliaAppId = window.algolia_app_id;
  const algoliaPublicKey = window.algolia_public_key;
  const algoliaIndexName = window.aloglia_collection_index_name;

  const collectionHitTemplate = `
        <a href="#" class="algolia-result py-2.5 px-3.5 hover:bg-gray-200 focus:bg-gray-200 active:bg-gray-200 no-underline text-black block" data-slug="{{ slug }}" data-image-url="{{image_url}}" data-name="{{name}}">
            <div class="item-container min-h-full flex items-center">
                <div class="relative">
                    <img src="{{image_url}}" alt="{{name}}" loading="lazy" width="35" height="35" class="rounded z-0 relative float-left mr-3">
                </div>
                <div class="text text-xs leading-5 box-border max-w-full flex flex-col justify-center items-start">
                    {{#name}}
                        <h6 class="m-0 font-medium">{{name}}</h6>
                    {{/name}}
                    {{#category_names.length}}
                        <div class="subtext leading-4 text-gray-700 text-left">
                            {{#category_names}}
                                <span class="subtext-item">{{.}}</span>
                            {{/category_names}}
                        </div>
                    {{/category_names.length}}
                </div>
            </div>
        </a>
    `;

  if ($("#navbar-collection-search-box").length > 0) {
    // console.log('Initializing desktop search');
    createAlgoliaSearch(
      algoliaAppId,
      algoliaPublicKey,
      'navbar-collection',
      algoliaIndexName,
      collectionHitTemplate,
      (data) => {
        window.location.href = `/${data.slug}`;
      },
      'Search for real people & brands',
      '<q>{{ query }}</q> is not on NotCommon yet...',
      'navbar-search-box',
      true  // Allow submitting on enter
    );
  }

  if ($("#navbar-collection-mobile-search-box").length > 0) {
    // console.log('Initializing mobile search');
    createAlgoliaSearch(
      algoliaAppId,
      algoliaPublicKey,
      'navbar-collection-mobile',
      algoliaIndexName,
      collectionHitTemplate,
      (data) => {
        window.location.href = `/${data.slug}`;
      },
      'Search for real people & brands',
      '<q>{{ query }}</q> is not on NotCommon yet...',
      'navbar-search-box',
      true  // Allow submitting on enter
    );
  }

});
