import { CountUp } from 'countup.js';

document.addEventListener('turbolinks:load', () => {

  // The versus winner percentage winner animated counter
  const percentageElement = $('#percentage');
  const endValue = parseFloat(percentageElement.data('end-value'));

  if (endValue) {
    // Adjust the duration here. The value is in seconds.
    const countUpOptions = {
      duration: 3, // Example: 3 seconds. Adjust this value as needed.
      decimalPlaces: endValue < 1 ? 1 : 0, // Use 1 decimal place for values less than 1, otherwise no decimal places
    };

    const countUp = new CountUp(percentageElement[0], endValue, countUpOptions);
    if (!countUp.error) {
      countUp.start();
    } else {
      console.error(countUp.error);
    }
  }

});