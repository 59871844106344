// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Add this line to import Chartkick and Chart.js
// import "chartkick/chart.js";

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// Added the following two lines to fix an error where "$ is not defined"
// Source: https://stackoverflow.com/questions/43803192/is-not-defined-within-a-js-erb-view-using-webpack-2-in-rails-app
import $ from 'jquery';
window.$ = window.jQuery = $;
//global.$ = jQuery;

// require("@popperjs/core")

require("packs/form_validation")
require("packs/noty")
require("packs/notification")
require("algoliasearch")
require("instantsearch.js")
require("@nathanvda/cocoon")
require("packs/tailwind_layout/algolia-custom")
require("packs/tailwind_layout/algolia-search-initializer")
require("packs/truncate_text")
require("packs/cytoscape_diagram")
require("packs/tailwind_layout/modals")

// For new pages built with Tailwind CSS
require("packs/tailwind_layout/custom")
require("packs/tailwind_layout/nav")
require("packs/tailwind_layout/home")
require("packs/tailwind_layout/profile")
require("packs/tailwind_layout/onboarding")
require("packs/tailwind_layout/lists")
require("packs/tailwind_layout/versus")

global.toastr = require("toastr")
Rails.start()
Turbolinks.start()
ActiveStorage.start()
