// app/javascript/packs/tailwind_layout/modals.js

$(document).on('turbolinks:load', function() {
  // Function to open a modal
  function openModal(modalId) {
    var $modal = $(modalId);
    $('body').addClass('overflow-hidden');
    $modal.removeClass('opacity-0 pointer-events-none').attr('aria-hidden', 'false');

    // Animate backdrop
    $modal.find('.modal-overlay')
      .removeClass('opacity-0')
      .addClass('opacity-100 transition-opacity ease-out duration-300');

    // Animate modal content
    $modal.find('.modal-content')
      .removeClass('opacity-0 scale-95')
      .addClass('opacity-100 scale-100 transition-all ease-out duration-300');

    // Set focus to the modal content
    $modal.find('.modal-content').attr('tabindex', '-1').focus();
  }

  // Function to close a modal
  function closeModal($modal) {
    // Animate backdrop
    $modal.find('.modal-overlay')
      .removeClass('opacity-100')
      .addClass('opacity-0 transition-opacity ease-in duration-200');

    // Animate modal content
    $modal.find('.modal-content')
      .removeClass('opacity-100 scale-100')
      .addClass('opacity-0 scale-95 transition-all ease-in duration-200');

    // Delay hiding the modal container until after the animation
    setTimeout(function() {
      $modal.addClass('opacity-0 pointer-events-none').attr('aria-hidden', 'true');
      $('body').removeClass('overflow-hidden');
    }, 200);
  }

  // Open modal when a button with data-modal-target is clicked
  $('[data-modal-target]').on('click', function(e) {
    e.preventDefault();
    var modalId = $(this).data('modal-target');
    openModal(modalId);
  });

  // Close modal when clicking on the close button
  $(document).on('click', '.modal-close-button', function(e) {
    e.preventDefault();
    var $modal = $(this).closest('.modal-container');
    closeModal($modal);
  });

  // Close modal when clicking outside the modal content
  $(document).on('click', '.modal-overlay', function() {
    var $modal = $(this).closest('.modal-container');
    closeModal($modal);
  });

  // Prevent clicks inside the modal content from closing the modal
  $(document).on('click', '.modal-content', function(e) {
    e.stopPropagation();
  });

  // Close modal when pressing the Escape key
  $(document).on('keydown', function(e) {
    if (e.key === "Escape") {
      $('.modal-container').each(function() {
        var $modal = $(this);
        if (!$modal.hasClass('hidden')) {
          closeModal($modal);
        }
      });
    }
  });

});
