$(document).on('turbolinks:load', function () {

  // Pagination element click behavior for the most followed and list pages
  // Add loading spinner to pagination link in lists
  $('.nc-most-followed-page').on('click', '#see-more-link', function(e) {
    e.preventDefault();
    $(this).html('<i class="fa-solid fa-circle-notch fa-spin"></i>'); // Show loading spinner
  });

  // Link to tweet us something
  $('.nc-tweet-link').click(function(e) {
    e.preventDefault();

    var tweetMessage = $(this).data('tweet-message');
    var tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetMessage)}`;
    popItUp(tweetUrl);
  });

});