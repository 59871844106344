// Define the browser detection function
function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName = 'unknown';

  if (/chrome|crios|crmo/i.test(userAgent) && !/edge|edg|opr|brave/i.test(userAgent)) {
    browserName = 'chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    browserName = 'firefox';
  } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo|android/i.test(userAgent)) {
    browserName = 'safari';
  } else if (/opr\//i.test(userAgent)) {
    browserName = 'opera';
  } else if (/edg/i.test(userAgent)) {
    browserName = 'edge';
  } else if (/brave/i.test(userAgent)) {
    browserName = 'brave';
  }

  // console.log('browserName', browserName);
  return browserName;
}

// Function to update extension upsell components
function updateExtensionUpsell($container) {
  const browserName = getBrowserName();

  const $extensionNameElements = $container.find('.browser-extension-name');
  const $browserNameElements = $container.find('.browser-name');
  const $links = $container.find('.extension-link');
  const $icons = $container.find('.browser-icon');

  let extensionName = 'Chrome Extension';
  let storeLink = $container.data('chrome-link');
  let browserDisplayName = 'Chrome';
  let iconClass = 'fa-brands fa-chrome';

  switch (browserName) {
    case 'chrome':
    case 'edge':
    case 'brave':
      // Defaults for Chrome-compatible browsers
      break;
    case 'firefox':
      extensionName = 'Firefox Add-on';
      storeLink = $container.data('firefox-link');
      browserDisplayName = 'Firefox';
      iconClass = 'fa-brands fa-firefox-browser';
      break;
    case 'safari':
      extensionName = 'Safari Extension';
      storeLink = $container.data('safari-link');
      browserDisplayName = 'Safari';
      iconClass = 'fa-brands fa-safari';
      break;
    default:
      extensionName = 'Browser Extension';
      storeLink = 'https://www.notcommon.com/extensions';
      browserDisplayName = 'your browser';
      iconClass = 'fa-solid fa-globe';
      break;
  }

  // Update the extension name in headings or texts
  $extensionNameElements.text(extensionName);

  // Update all browser name elements
  $browserNameElements.text(browserDisplayName);

  // Update links
  $links.each(function() {
    const $link = $(this);
    $link.attr('href', storeLink);
  });

  // Update icons
  $icons.each(function() {
    const $icon = $(this);
    $icon.attr('class', iconClass + ' mr-2 browser-icon');
  });
}

// Handles a popup window that opens up the given URL
window.popItUp = function(url) {
  // Calculate the left position to center the popup window
  var left = (window.screen.width / 2) - 325;

  // Open a new window with specified dimensions and position
  var newWindow = window.open(url, '_blank', 'height=600,width=650,left=' + left);

  // Focus the new window if it's successfully opened
  if (newWindow) {
    newWindow.focus();
  } else {
    console.error('Failed to open the popup window. Check if the browser blocked the popup.');
  }

  return false;
}


document.addEventListener('turbolinks:load', () => {

  // Show spinner inside the submit button when a form with class .auth-form is submitted
  $(".auth-form").on("submit", function (e) {
    // Do not show loader if form is not in valid state
    if ($(this).valid() === false) { return; }

    // Spinning icon from font awesome
    const loadingHtmlBtn = `
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      `;
    const submitBtn = $(this).find('button[type="submit"]'); // Find form submit button
    submitBtn.attr("disabled", "disabled"); // Disable it
    submitBtn.attr("data-text", submitBtn.text()); // Save original text in data-text attribute
    submitBtn.html(loadingHtmlBtn); // Set inner html of button to the spinning icon
  });

  ///////////////////////////////////////////////

  // SHOW LOADING SPINNER AND MESSAGE WHEN SUBMITTING FORM
  // Apply to any form with the class 'nc-loading-on-submit'
  $("form.nc-loading-on-submit").on("submit", function (e) {
    const form = $(this);

    // Get the loading text from data attribute or default to 'Loading...'
    const loadingText = form.data('loading-text') || 'Loading...';

    // HTML for the loading state
    const loadingHtmlBtn = `
      <i class="fa-solid fa-circle-notch fa-spin"></i>
      <span class="ml-2">${loadingText}</span>
    `;

    // Find the submit button(s) within the form
    const submitBtn = form.find('button[type="submit"], input[type="submit"], button:not([type])');

    // Disable the submit button(s) and set the loading HTML or value
    submitBtn.each(function() {
      const btn = $(this);
      btn.attr("disabled", "disabled");

      if (btn.is('button')) {
        btn.html(loadingHtmlBtn);
      } else if (btn.is('input')) {
        btn.val(loadingText);
      }
    });
  });

  ///////////////////////////////////////////////

  // Update all extension upsell components
  const $upsellContainers = $('[data-extension-upsell]');
  $upsellContainers.each(function() {
    const $container = $(this);
    updateExtensionUpsell($container);
  });

  ///////////////////////////////////////////////

  $(document).on('click', '.nc-copy-trigger', function(e) {
    e.preventDefault();

    // Get the URL or text from the data-url attribute
    var textToCopy = $(this).data('url');

    if (textToCopy) {
      // Create a temporary input element to hold the text
      var $tempInput = $('<input>');
      $('body').append($tempInput);
      $tempInput.val(textToCopy).select();

      // Copy the text to the clipboard
      document.execCommand('copy');

      // Remove the temporary input element
      $tempInput.remove();

      // Close the dropdown menu after copying the URL (this is needed for the profile page)
      $(this).closest('.nc-link-actions-dropdown').addClass('hidden');

      // Show a success message using toastr (optional)
      toastr.success('URL copied to clipboard');
    }
  });

  ///////////////////////////////////////////////

  // Unified function to handle smooth scrolling on click
  $(".nc-scroll-smoothly-to").click(function (e) {
    e.preventDefault(); // Prevent default anchor click behavior

    // Get the target element and optional offset from the clicked link
    const target = $(this).attr("href");
    const offsetTop = Number($(this).data("offset")) || 0;
    const $target = $(target);

    // Exit if the target element does not exist
    if (!$target.length) return;

    // Define navbar height if it's dynamically set
    const navbarHeight = window.navbarHeight || 0; // Adjust based on your layout

    // Smooth scroll to the target element
    $('html, body').stop().animate({
      scrollTop: $target.offset().top - navbarHeight + offsetTop
    });
  });


});
